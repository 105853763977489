import React from "react";
import CV from "../../assets/cv.pdf";
import Link from "../button/link";
import "./links.scss";
const Links = () => {
  return (
    <div className="links">
      <Link href={CV} download={true} text="DOWNLOAD CV" />
      <Link href="#contact" text="CONTACT ME" />
    </div>
  );
};

export default Links;
