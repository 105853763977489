import React from "react";
import { useRef, useState } from "react";
import "./about.scss";
import Modal from "./modal";

import { useTransition } from "transition-hook";
import Sites from "./sites";

const skills = [
  { title: "Experience", description: "+10 years working in IT" },
  { title: "Clients", description: "+20 happy clients" },
  { title: "Projects", description: "+20 completed" },
  { title: "Mates", description: "+1000 preparated" },
];

const About = (props) => {
  const modalRef = useRef();
  const [onOff, setOnOff] = useState(true);
  const { stage, shouldMount } = useTransition(onOff, 300);
  return (
    <div id="about" className="about-section section">
      <div className="title">
        <h2>About me </h2>
      </div>

      <div className="about-content">
        <p>
          I am a full stack developer with experience as a technical lead and a
          strong understanding of DevOps principles. While I have a solid
          foundation in computer science and a passion for continuously learning
          and staying up-to-date with the latest technologies, I am always
          looking to improve and expand my skills. I have had the opportunity to
          work on a variety of projects, from small startups to large enterprise
          applications, and have learned a lot from each experience. I am a team
          player and enjoy collaborating with others to solve complex problems
          and deliver high-quality software. I believe that by working together
          and being open to new ideas, we can achieve great things.
        </p>
      </div>
      {props.position > 1190 && (
        <div className="skills">
          {skills.map((skill, key) => (
            <div key={key} className="skill-box">
              <h1 className="number">0{key + 1}</h1>
              <h3 className="title">{skill.title}</h3>
              <h1 className="detail">{skill.description}</h1>
            </div>
          ))}
        </div>
      )}
      <Sites />
      {/* <button onClick={() => modalRef.current.open()}>ABRIR</button>
      <Modal ref={modalRef} /> */}
      {/* {shouldMount && (
        <div
          className=""
          style={{
            transition: "3s",
            overflow: "hidden",
            opacity: stage === "enter" ? 1 : 0,
            transform: {
              from: "translateY(-200px)",
              enter: "translateY(0%)",
              leave: "translateY(-200%)",
            }[stage],
          }}
        >
          <Sites />
        </div>
      )} */}

      {/* <button onClick={() => setOnOff(!onOff)}>View more</button> */}
    </div>
  );
};

export default About;
