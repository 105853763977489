import React from "react";
import { useState, useEffect, useRef } from "react";
import { NavLink } from "react-router-dom";
import "./header.scss";

const Header = (props) => {
  const [active, setActive] = useState(null);
  //const [screenWidth, setScreenWidth] = useState(0);
  const handleActive = (active) => {
    setActive(active);
    document.getElementById(active).scrollIntoView({ behavior: "smooth" });
  };

  // useEffect(() => {
  //   setScreenWidth(window.innerWidth);
  // }, []);

  return (
    <div className="nav">
      <div className="w-container nav-container">
        {props.width > 700 ? (
          <div className="logo">
            <a href="#">Matias Ramirez</a>
          </div>
        ) : (
          <div className="logo-mb">
            <a href="#">MR</a>
          </div>
        )}
        <div className="nav-menu">
          <p
            onClick={() => handleActive("about")}
            className={active === "about" ? "active" : ""}
          >
            About
          </p>
          <p
            onClick={() => handleActive("experience")}
            className={active === "experience" ? "active" : ""}
          >
            Experience
          </p>
          <p
            onClick={() => handleActive("contact")}
            className={active === "contact" ? "active" : ""}
          >
            Contact
          </p>
        </div>
      </div>
    </div>
  );
};

export default Header;
