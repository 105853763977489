import React from "react";
import "./link.scss";
const Link = (props) => {
  const { extraStyle, href, text, download } = props;
  return (
    <div className={"link " + (extraStyle ? extraStyle : "")}>
      <a
        href={href}
        download={download ? true : false}
        className="btn btn-primary"
      >
        {text}
      </a>
    </div>
  );
};

export default Link;
