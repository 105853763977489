import React from "react";
import { BsLinkedin } from "react-icons/bs";
import { FaGithub } from "react-icons/fa";
import { GrInstagram } from "react-icons/gr";
import "./socials.scss";
const Socials = () => {
  return (
    <div className="socials-container">
      <a
        href="https://www.linkedin.com/in/matias-ramirez-5a15ba5b"
        target="_blank"
        rel="noreferrer"
      >
        <BsLinkedin size={30} />
      </a>
      <a href="https://github.com/matiase9" target="_blank" rel="noreferrer">
        <FaGithub size={30} />
      </a>
      {/* <a
        href="https://www.instagram.com/matiase9/"
        target="_blank"
        rel="noreferrer"
      >
        <GrInstagram size={30} />
      </a> */}
    </div>
  );
};

export default Socials;
