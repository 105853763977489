import React, { useEffect, useState } from "react";
import Navbar from "../../components/navbar";
import "./home.scss";
// import Header from "../../components/layout/Header";
import { FaPhp, FaReact, FaPython, FaNodeJs, FaSass } from "react-icons/fa";
import Intro from "../../compo/intro";
import Layout from "../../compo/layout";
import Companies from "../../compo/companies";
import About from "../../compo/about";
import Experience from "../../compo/experience";
import Contact from "../../compo/contact";
import Footer from "../../compo/footer";
import Header from "../../compo/header";
import Projects from "../../compo/projects";
const Home = (props) => {
  const [width, setWidth] = useState(window.innerWidth);
  const [position, setPosition] = useState(false);
  //const [height, setHeight] = useState(window.innerHeight);
  const updateDimensions = () => {
    setWidth(window.innerWidth);
    //setHeight(window.innerHeight);
  };
  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  const handleScroll = () => {
    const position = window.pageYOffset + window.innerHeight;
    console.log(position);
    setPosition(position);
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div className="home-page">
      <Header width={width} />
      <Layout>
        <Intro width={width} />
        <Companies />
        <About position={position} />
        <Projects width={width} />
        <Experience />
        <Contact />
      </Layout>
      <Footer />
      {/* <Header />
      <Intro />
      <Companies />
      <About />
      <Projects /> */}
      {/* <img src={Sun} alt="" className="App-logo" /> */}
      {/* <div>
        <FaPhp className="App-logo-php" />
        <FaReact className="App-logo-react" />
        <FaPython className="App-logo-py" />
        <FaNodeJs className="App-logo-node" />
        <FaSass className="App-logo-sass" />
      </div> */}
      {/* <Navbar />

      <Experience />
      <Contact />
      <Footer /> */}
    </div>
  );
};

export default Home;
