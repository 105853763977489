import React from "react";
import "./sites.scss";
const projects = [
  { url: "https://www.cegid.com/", name: "Cegid" },
  { url: "https://www.vibia.com/", name: "Vibia" },
  { url: "https://juicebeauty.com", name: "Juice Beauty" },
  { url: "https://www.oscardelarenta.com/", name: "Oscar de la Renta" },
  { url: "https://us.zadig-et-voltaire.com/", name: "Zadig Et Voltaire" },
  { url: "https://www.guideboat.com/", name: "Guide Boat" },
  { url: "https://www.eberjey.com/", name: "Eberjey" },
  { url: "https://www.abchome.com/", name: "ABC Home" },
  { url: "https://www.shopdesign.mx", name: "Shop design" },
  { url: "https://tienda.eltiempo.com", name: "El tiempo" },
  { url: "https://www.tiendanaranja.com", name: "Tienda Naranja" },
  { url: "https://www.wholesaleflowersandsupplies.com/", name: "WSF&S" },
  { url: "https://languagetrainers.com", name: "Language Trainers" },
  { url: "http://www.asphaltandiron.com/", name: "Asphalt & Iron" },
  { url: "http://www.teamcone.net", name: "Team Cone" },
  { url: "https://www.siu.edu.ar/", name: "SIU" },
  { url: "https://unitech.com.ar/", name: "Unitech" },
  { url: "https://www.triple888.com.au", name: "Triple 888" },
  { url: "https://www.artistictile.com/", name: "Artistic Tile" },
  { url: "https://www.stormtechlabs.com/", name: "Storm Tech" },
  { url: "https://www.loefflerrandall.com/", name: "Loeffler Randall" },
].sort((a, b) => {
  if (a.name < b.name) {
    return -1;
  }
  if (a.name > b.name) {
    return 1;
  }
  return 0;
});

const Sites = () => {
  return (
    <div className="container__sites">
      <div className="title">
        <h2>Contributions in:</h2>
      </div>

      <div className="container__list">
        {projects.map((item) => {
          return (
            <div className="box__site">
              <a href={item.url} target="_blank" rel="noopener noreferrer">
                {item.name}
              </a>
              {/* <button
                onClick={() => {
                  window.open("www.google.com", "_blank");
                  window.open(this.href, "_self");
                }}
              >
                {item.url}
              </button> */}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Sites;
