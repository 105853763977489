import React from "react";
import { FaFacebook } from "react-icons/fa";
import { FiInstagram } from "react-icons/fi";
import { IoLogoTwitter } from "react-icons/io";
import "./footer.scss";
const Footer = () => {
  return (
    <footer>
      <a href="#" className="footer__logo">
        MATIAS RAMIREZ
      </a>
      <ul className="permalinks">
        <li>
          <a href="#">Home</a>
        </li>
        <li>
          <a href="#about">About</a>
        </li>
        <li>
          <a href="#experience">Experience</a>
        </li>
        <li>
          <a href="#contact">Contact</a>
        </li>
      </ul>

      {/* <div className="footer__socials" target="_blank">
        <a href="http://facebook.com">
          <FaFacebook />
        </a>
        <a href="http://instagram.com" target="_blank">
          <FiInstagram />
        </a>
        <a href="http://twitter.com" target="_blank">
          <IoLogoTwitter />
        </a>
      </div> */}

      <div className="footer__copyright">
        <small>&copy; Matias Ramirez. All rights reserved.</small>
      </div>
    </footer>
  );
};

export default Footer;
