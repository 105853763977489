import React, { useState, useEffect } from "react";
import "./projects.scss";
import VibiaImg from "../../assets/vibia-project.png";
import MoonImg from "../../assets/moonlegend.png";
import OscarImg from "../../assets/oscar.png";

const Projects = (props) => {
  const [active, setActive] = useState(null);

  const handleActivate = (active) => {
    setActive(active);
  };
  const motionMatchMedia = window.matchMedia("(prefers-reduced-motion)");

  function handleHover(e) {
    const THRESHOLD = 15;
    const { clientX, clientY, currentTarget } = e;
    const { clientWidth, clientHeight, offsetLeft, offsetTop } = currentTarget;
    const horizontal = (clientX - offsetLeft) / clientWidth;
    const vertical = (clientY - offsetTop) / clientHeight;
    const rotateX = (THRESHOLD / 2 - horizontal * THRESHOLD).toFixed(2);
    const rotateY = vertical.toFixed(2);
    if (currentTarget.style && props.width > 1000) {
      currentTarget.style.transform = `perspective(${clientWidth}px) rotateX(${rotateY}deg) rotateY(${rotateX}deg) scale3d(1, 1, 1)`;
      currentTarget.querySelector("h4").style.scale = 1.04;
    }
  }

  function resetStyles(e) {
    const { currentTarget } = e;
    if (currentTarget.style && props.width > 1000) {
      currentTarget.style.transform = `perspective(${e.currentTarget.clientWidth}px) rotateX(0deg) rotateY(0deg)`;
      currentTarget.querySelector("h4").style.scale = 1;
    }
  }

  useEffect(() => {
    const cards = document.querySelectorAll(".box");

    if (cards) {
      console.log("2222");
      if (!motionMatchMedia.matches) {
        cards.forEach((card) => {
          card.addEventListener("mousemove", handleHover);
          card.addEventListener("mouseleave", resetStyles);
        });
      }
    }
  }, []);

  return (
    <div id="projects" className="projects-section section">
      <div className="title">
        <h2>Recent Projects</h2>
        <div className="box left" onClick={() => handleActivate("vibia")}>
          <div className="box__img">
            <img src={VibiaImg} />
          </div>
          <div className="box__detail">
            <h4>Vibia - React Configurator</h4>
            {/* <h4 className={active === "vibia" ? "active" : ""}>
              Vibia - React Configurator
            </h4> */}
          </div>
        </div>
      </div>
      <div className="container__projects">
        <div className="box right" onClick={() => handleActivate("moon")}>
          <div className="box__img">
            <img src={MoonImg} />
          </div>
          <div className="box__detail">
            <h4>NFT Game - React/Web 3.0</h4>
            {/* <h4 className={active === "moon" ? "active" : ""}>
              NFT Game - React/Web 3.0
            </h4> */}
          </div>
        </div>
        <div className="box right" onClick={() => handleActivate("oscar")}>
          <div className="box__img">
            <img src={OscarImg} />
          </div>
          <div className="box__detail">
            <h4>Oscar de la Renta</h4>
            {/* <h4 className={active === "oscar" ? "active" : ""}>
              Oscar de la Renta
            </h4> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Projects;
