import React from "react";
import "./companies.scss";
import SummaImg from "../../assets/summa.png";
import VibiaImg from "../../assets/vibiat.png";
import CegidImg from "../../assets/cegid.svg";
import TripleImg from "../../assets/triple888.svg";
import SiuImg from "../../assets/siu.png";

const companies_list = [
  { name: "Cegid", link: "", img: CegidImg },
  { name: "Vibia Ligthing", link: "", img: VibiaImg },
  { name: "Summa Solutions", link: "", img: SummaImg },
  { name: "Triple 888", link: "", img: TripleImg },
  { name: "SIU", link: "", img: SiuImg },
];

const Companies = () => {
  return (
    <div className="companies">
      <div className="companies__content">
        <h3>COLLABORATE WITH</h3>
        <div className="companies__list w-container">
          {companies_list.map((company, key) => (
            <img key={key} src={company.img} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Companies;
