import React from "react";
import "./experience.scss";
const experience_data = [
  {
    title: "Moon Legend",
    position: "Co founder",
    date_start: "Oct 2022",
    date_end: "Present",
    mode: "Freelance",
    description:
      "Working with React in a NFT game (I made all the graphical interface in frontend).\n\
Web3 wallets and integration with App.\n\
Working with various blockchain development tools, such as Truffle and Ganache.\n\
Keywords: Web3, Redux, Websocket, etc. ",
  },
  {
    title: "Cegid",
    position: "Software developer (Belgium - Remote)",
    date_start: "Mar 2022",
    date_end: "Oct 2022",
    mode: "Fulltime",
    description:
      "Working in the serverless team (Python-AWS) and PHP team as Senior developer.\n\
Frontend in React using redux, sass, storybook, jest, etc.\n\
Integration team: Aws (lambdas, step functions, dynamoDB, S3, cloudformation, etc).\n\
PHP team: laravel, zend, symfony, etc",
  },
  {
    title: "Vibia ligthing",
    position: "Software developer / Tech Lead (Spain)",
    date_start: "Jan 2020",
    date_end: "Mar 2022",
    mode: "Fulltime",
    description:
      "Details \n\
Stack & Struct: Nodejs, React, Express, MongoDB, CI, Redux, Storybook, Jest \n\
Concepts: SOLID concept, TDD. \n\
Projects:\n\
• Integration of manipulator 3d (angular with webgl) and React.\n\
• React native app to give support to the clients.\n\
• Migration of code legacy (PHP - Zend/Symfony) to new microservices project (Nodejs).\n\
Rol:\n\
• Development.\n\
• Developer code review.\n\
• Translation of the UI/UX design wireframes to the code.\n\
• Organize and assign tasks in Jira.\n\
• Give support to my team.\n\
• Estimate and calculate development time.\n\
• Fix issues in the CI when the deploy is not success.\
    ",
  },
  {
    title: "Triple 888 Studios",
    position: "Development Specialist (Australia - Remote)",
    date_start: "Nov 2019",
    date_end: "Jan 2020",
    mode: "Part-time",
    description:
      "Details:\n\
• Analyse the project and I recommended a new struct because the project didn't have good practice in the front area.\n\
• I created the base to the new struct, styles, product page, etc.",
  },
  {
    title: "Summa Solutions",
    position: "Senior Software Developer (Argentina)",
    date_start: "Jan 2016",
    date_end: "Nov 2019",
    mode: "Fulltime",
    description:
      "Developer at Summa Solutions, a company specialized in e-commerce using cutting-edge technologies.\n\
      \nResponsibilities: \n \
• Build websites in Magento 1.x, 2.x, Wordpress (Woocommerce).\n \
• Optimizating and customizating of Magento or third-party modules.\n\
• Microservices to integrate with Magento.\n\
• Task planning using agile methods.\n\
• Knowledge of Docker, Composer, Jenkins, ElasticSearch, Solr, Mailchimp, jMeter, Less, Sass, Gulp, Nodejs, Expressjs, Mongodb etc.\n\
• Implementations with React, GraphQl, PWA.\n\
\nProjects and Roles:\n\
• Onerockwell Team - (+20 Projects) https://www.onerockwell.com/work/ (Backend Developer)\n\
• Shopdesign - https://www.shopdesign.mx - (FullStack Developer)\n\
• El Tiempo - Colombia (Frontend Developer)\n\
• Tienda Naranja - www.tiendanaranja.com Argentina (Senior/TL Magento)",
  },
  {
    title: "Stormtech",
    position: "Full stack (Remote)",
    date_start: "May 2016",
    date_end: "Feb 2019",
    mode: "Part-time",
    description:
      "Coordinating with a group of engineers and clients from different locations in Buenos Aires. We design and develop high-end software applications customizables to necessity of clients.\n\
Framework Django (Python 2.x, 3.0)",
  },
  {
    title: "Freelance",
    position: "Senior Web Developer (Remote)",
    date_start: "Feb 2015",
    date_end: "Apr 2018",
    mode: "Part-time",
    description:
      "Software developer in Evok (Orlando-Florida)\n\
Projects:\n\
https://www.asphaltandiron.com/ \n\
https://www.teamcone.net/\n\
\n\
Backend Developer in Wholesaleflowersandsupplies (USA)\n\
https://www.wholesaleflowersandsupplies.com/\n\
\n\
Software developer in LanguageTrainers (UK)\n\
https://www.languagetrainers.com/\n\
    ",
  },
  {
    title: "SIU",
    position: "Web Developer (Argentina)",
    date_start: "Jun 2013",
    date_end: "Jun 2015",
    mode: "Fulltime",
    description:
      "Analyst programmer of the software development team used for Human Resource Management in Public Organizations and other Institutions in Argentina.\n\
Responsibilities:\n\
• Development of Back-end tasks (PHP).\n\
• Creation of applications using D3js.\n\
• Use of agile methodologies for task planning.\n\
• Attention to users.",
  },
  {
    title: "Unitech",
    position: "Backend developer (Argentina)",
    date_start: "Jan 2013",
    date_end: "Jun 2013",
    mode: "Fulltime",
    description: "Analyst/Developer in TRAMIX system (SAN LUIS - ARGENTINA)",
  },
];

const Experience = () => {
  return (
    <div id="experience" className="experience-section section">
      <div className="title">
        <h2>Experience </h2>
      </div>
      <div className="container__experience">
        <div className="experience__cards">
          {experience_data.map((exp, key) => (
            <article className="experience__card" key={key}>
              <div className="title__card">
                <h2>{exp.title}</h2>
                <h5>
                  ({exp.date_start}~{exp.date_end}) - {exp.mode}
                </h5>
              </div>

              <small>{exp.position}</small>
              <p className="description__text">{exp.description}</p>
            </article>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Experience;
