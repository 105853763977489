import React from "react";
import { AiOutlineMail, AiTwotonePhone } from "react-icons/ai";
import "./contact.scss";
import Socials from "./socials";

const Contact = () => {
  return (
    <div id="contact" className="contact-section">
      <div className="title">
        <h2>Contact </h2>
      </div>
      <div className="container-information">
        <div className="info">
          <p>
            If you'd like to talk about a project you want help with or need an
            advice about development, just drop me a message at
            <span>
              <AiOutlineMail />
              matiase9@gmail.com
            </span>
            .
          </p>
        </div>
        {/* <div className="info">
          <AiTwotonePhone />
          <p>+61 450 380 377</p>
        </div> */}
        <Socials />
      </div>
    </div>
  );
};

export default Contact;
