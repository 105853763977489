import React, { useEffect, useState } from "react";
import "./intro.scss";
import ME from "../../assets/me.png";
import Links from "./links";
const Intro = (props) => {
  return (
    <div className="main-section">
      <div className="side-content">
        <h3>Hello, I'm</h3>
        <h2>Matias Ramirez</h2>
        <p>
          An enthusiastic software developer with knowledge in
          Backend/Frontend/Devops. My background includes corporate and
          freelance experience, with a history of development projects. I have
          worked on nationwide and international teams and products for global
          brands.
        </p>
        <Links />
      </div>
      {props.width > 700 && (
        <div className="img-content">
          <div className="me">
            <img src={ME} alt="me" />
          </div>
        </div>
      )}
    </div>
  );
};

export default Intro;
